<template>
  <section class="category_section bg-brand-product">
    <div class="container">
      <img v-if="productLoading" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height: 80px" alt="logo" />
      <div v-else class="row">
        <nav class="breadcrumb">
          <ul>
            <li>
              <router-link :to="{ name: 'home' }">Home</router-link>
              >>
            </li>
            <li>Category >></li>
            <li>
              <router-link :to="{ name: 'category', params: { slug: category.slug } }">{{
                category.name
              }}</router-link>
            </li>
          </ul>
        </nav>
        <!--=====
        <pre>{{category}}</pre>
        =====-->
        <div class="col-lg-12 col-md-8">
          <div v-if="allProduct.length > 0" class="row product_row custom-roduct-grid">
            <!--class="col-xl-3 col-lg-3 col-sm-6"-->

            <!--<div v-for="product in products.filter((e) => e.product_type.length > 0 )" :key="product.id" class="col-6 col-md-3">-->
            <div class="custom-grid" v-for="product in allProduct" :key="product.id">
              <div class="brand-p-area-section">
                <div class="product_area product-area-wrap brand-p-area">
                  <figure class="product_img">
                    <router-link target="_blank" :to="{
                      name: 'product',
                      params: { slug: product.slug, type: product.product_type },
                    }">
                      <div v-if="product.product_type === 1 || product.product_type === 3">
                        <div id="sold_out" style="position: absolute; z-index: 1; margin-top: -15px" v-if="!$store.getters.isExist(product.id) &&
                          product.product_price[0].quantity <= 0">
                          <img style="background: none;" src="/modifyfile/images/sold_out1.png" />
                        </div>
                      </div>
                      <img v-if="
                        product.product_images.filter((img) => img.web_id === 2).length
                      " :src="'https://eurasiasupplies.com/euadmin/' +
                        product.product_images.filter((img) => img.web_id == 2)[0].image
                        " alt="" />
                    </router-link>
                    <a v-if="!product.is_wishlist" @click="addWishList(product)" class="favorit">
                      <i class="icon ion-md-heart"></i>
                      <i class="icon ion-md-heart-empty"></i>
                    </a>
                    <a v-if="product.is_wishlist" class="favorit">
                      <i class="icon ion-md-heart" style="display: block !important"></i>
                    </a>
                  </figure>
                  <button class="quick-view" @click="quickView(product)">
                    QUICK VIEW
                  </button>
                  <!--<pre>{{product}}</pre>-->
                  <div v-if="product.product_type === 1 || product.product_type === 3" class="product_title">
                    <router-link target="_blank" :to="{
                      name: 'product',
                      params: { slug: product.slug, type: product.product_type },
                    }">
                      <h4>{{ product.name }}</h4>

                      <!-- <a href=""><h4>{{ product.name }}</h4></a> -->
                      <div class="product-price-wrap">
                        <div class="single-product-price" style="color: #f60; font-weight: bold; font-size: 14px"
                          v-if="product.product_price">
                          <table style="
                              border-spacing: 0px;
                              float: left;
                              width: 100%;
                              margin-bottom: 3px;
                            ">
                            <tbody>
                              <tr v-if="product.product_price[0].offer_price !== 0">
                                <td class="start-at" style="
                                    text-align: left;
                                    letter-spacing: -1px;
                                    font-family: verdana, arial, helvetica, sans-serif;
                                  ">
                                  <b>Was: </b>
                                </td>
                                <td class="start-at" style="
                                    text-align: left;
                                    letter-spacing: -1px;
                                    font-family: verdana, arial, helvetica, sans-serif;
                                  ">
                                  <s>{{ product.product_price[0].price }}৳</s>
                                </td>
                              </tr>
                              <!--                          <pre>{{product.product_price[0]}}</pre>-->
                              <tr>
                                <td class="start-at" style="
                                    text-align: left;
                                    letter-spacing: -1px;
                                    font-family: verdana, arial, helvetica, sans-serif;
                                  ">
                                  <b>Now: </b>
                                </td>
                                <td class="start-at" style="
                                    text-align: left;
                                    letter-spacing: -1px;
                                    font-family: verdana, arial, helvetica, sans-serif;
                                  ">
                                  {{
                                    product.product_price[0].offer_price
                                      ? product.product_price[0].offer_price
                                      : product.product_price[0].price
                                  }}৳
                                  <span v-if="product.product_price[0].price">
                                    (
                                    {{
                                      percentCalc(
                                        product.product_price[0].price,
                                        product.product_price[0].offer_price
                                          ? product.product_price[0].offer_price
                                          : product.product_price[0].price
                                      )
                                    }}% )
                                    <small style="
                                        color: grey;
                                        font-weight: 100;
                                        letter-spacing: 1px;
                                      ">/piece</small>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <!--                        BDT
                        <span v-if="!proPrice">{{
                            product.product_price[0].offer_price
                                ? product.product_price[0].offer_price
                                : product.product_price[0].price
                          }}
                        TK</span>
                        <span v-if="proPrice">{{ proPrice }} TK</span>
                        <del v-if="product.product_price[0].offer_price">
                          {{ product.product_price[0].price }} Tk
                        </del>-->
                        </div>
                      </div>
                    </router-link>
                    <div v-if="$store.getters.isExist(product.id)" class="product_action clearfix">
                      <div class="product-add-deduct">
                        <ul>
                          <li>
                            <button @click="deductQty(product)" type="button" class="btn btn-default btn-group-xs">
                              <i class="fa fa-minus"></i>
                            </button>
                          </li>
                          <li>{{ $store.getters.prodQty(product.id) }}</li>
                          <li>
                            <button type="button" class="btn btn-default btn-group-xs" :disabled="product.product_price[0].quantity ===
                              $store.getters.prodQty(product.id)
                              " @click="addQty(product)">
                              <i class="fa fa-plus"></i>
                            </button>
                            <!--                            <button  @click="addQty(product)" type="button" class="btn btn-default btn-group-xs"><i class="fa fa-plus"></i></button>-->
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-if="
                      !$store.getters.isExist(product.id) &&
                      product.product_price[0].quantity > 0
                    " class="product_action">
                      <button style="width: 100%" class="plus product-add-btn"
                        @click="addToBagForSingleProduct(product)">
                        Add to bag
                      </button>
                    </div>
                    <div v-if="
                      !$store.getters.isExist(product.id) &&
                      product.product_price[0].quantity <= 0
                    " class="product_action">
                      <button class="plus product-add-btn notify" @click="notifyMe(product)">
                        Sold Out. Notify Me
                      </button>
                    </div>
                  </div>
                  <div v-if="product.product_type === 2" class="product_title">
                    <!--<pre>{{product}}</pre>-->
                    <router-link target="_blank" class="title-height" :to="{
                      name: 'product',
                      params: { slug: product.slug, type: product.product_type },
                    }">
                      <h4>{{ product.name }}</h4>
                    </router-link>

                    <p class="product-price" v-if="product.min_price">
                      <span v-if="!proPrice">
                        <span v-if="product.min_offer_price > 0">Start At: ৳
                          {{ product.min_offer_price ? product.min_offer_price : "" }} TK
                          &nbsp;<del>৳
                            {{ product.min_offer_price ? product.min_price : "" }} TK</del></span>
                        <span v-else>Start At: ৳ {{ product.min_price }} TK</span>
                      </span>
                      <span v-else="">৳ {{ product.min_price }} - {{ product.max_price }} TK</span>
                      <span v-if="proPrice">{{ proPrice }} TK</span>
                    </p>
                    <div class="product_action action-details">
                      <router-link target="_blank" class="details" :to="{
                        name: 'product',
                        params: { slug: product.slug, type: product.product_type },
                      }">
                        Details
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--<NotifyModal v-model="showModal" :product="notifyMeData" :modalView="showModal"></NotifyModal>-->
            <div v-if="showModal">
              <transition name="modal">
                <div class="modal-mask">
                  <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header eu-modal-header">
                          <h5 class="modal-title eu-modal-title" style="color: #fff">
                            {{ notifyMeData.name }}
                          </h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="showModal = false" style="font-weight: 100">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="form-group" style="margin-bottom: 1rem">
                            <input type="text" class="form-control" v-model="name" placeholder="Your Name" />
                          </div>
                          <div class="form-group">
                            <input type="text" class="form-control" v-model="mobileNo"
                              placeholder="Your Mobile Number" />
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" @click="showModal = false">
                            Close
                          </button>
                          <button type="button" class="btn btn-primary"
                            @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">
                            Notify Me
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <!--          Quick View Modal              -->
            <div v-if="quickViewModal">
              <transition name="modal">
                <div class="modal-mask">
                  <div class="modal-wrapper">
                    <div class="modal-dialog modal-lg modal-ku" role="document">
                      <div class="modal-content">
                        <div class="modal-header eu-modal-header">
                          <h5 class="modal-title eu-modal-title" style="color: #fff">
                            {{ productDetails.name }}
                          </h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            style="font-weight: 100">
                            <span aria-hidden="true" @click="quickViewModal = false">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <!--<pre>{{productDetails}}</pre>-->
                          <img v-if="loading" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height: 80px"
                            alt="logo" />
                          <template v-if="
                            productDetails.product_type === 1 ||
                            productDetails.product_type === 3
                          ">
                            <div v-if="!loading" class="row">
                              <div class="col-md-12">
                                <div class="col-md-6">
                                  <div class="thumbnail-img col-md-2">
                                    <ul>
                                      <li class="thumb-img" v-for="image in productDetails.product_images">
                                        <img @click="asignImage(image.large_image)" :src="'https://eurasiasupplies.com/euadmin/' +
                                          image.large_image
                                          " alt="" />
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col-md-12 wrap-border">
                                    <div class="immage-zoomer">
                                      <inner-image-zoom class="large-img-sz" :src="'https://eurasiasupplies.com/euadmin/' +
                                        `${inImage == null
                                          ? productDetails.product_images[0]
                                            .large_image
                                          : inImage
                                        }`
                                        " :zoomScale="1" zoomType="hover" />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <!--<pre>{{productDetails}}</pre>-->
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="product-details">
                                        <div class="product-desc">
                                          <h4>{{ productDetails.name }}</h4>
                                          <p>
                                            by
                                            <b class="brand-name">
                                              <router-link target="_blank" :to="{
                                                name: 'brand',
                                                params: {
                                                  slug: productDetails.brand.slug,
                                                },
                                              }">
                                                {{ productDetails.brand.name }}
                                              </router-link>
                                            </b>
                                          </p>
                                          <p class="sku">
                                            <b>SKU : {{ productDetails.sku }}</b>
                                          </p>
                                        </div>
                                        <div class="price">
                                          <b>Sale:
                                            <span class="price-tag" style="font-size: 14px">
                                              <span v-if="
                                                productDetails.product_price[0]
                                                  .offer_price > 0
                                                  ? productDetails.product_price[0]
                                                    .price > 0
                                                    ? productDetails.product_price[0]
                                                      .price
                                                    : ''
                                                  : '' !== ''
                                              ">
                                                Was:
                                                <del>{{
                                                  productDetails.product_price[0]
                                                    .offer_price > 0
                                                    ? productDetails.product_price[0]
                                                      .price > 0
                                                      ? productDetails.product_price[0]
                                                        .price
                                                      : ""
                                                    : ""
                                                }}</del>৳</span>
                                              <span>
                                                Now:
                                                {{
                                                  productDetails.product_price[0]
                                                    .offer_price
                                                    ? productDetails.product_price[0]
                                                      .offer_price
                                                    : productDetails.product_price[0]
                                                      .price
                                                }}৳
                                                <span v-if="
                                                  productDetails.product_price[0]
                                                    .offer_price > 0
                                                    ? productDetails.product_price[0]
                                                      .price > 0
                                                      ? productDetails.product_price[0]
                                                        .price
                                                      : ''
                                                    : '' !== ''
                                                ">(
                                                  {{
                                                    percentCalc(
                                                      productDetails.product_price[0]
                                                        .offer_price > 0
                                                        ? productDetails.product_price[0]
                                                          .price > 0
                                                          ? productDetails
                                                            .product_price[0].price
                                                          : ""
                                                        : "",
                                                      productDetails.product_price[0]
                                                        .offer_price
                                                        ? productDetails.product_price[0]
                                                          .offer_price
                                                        : productDetails.product_price[0]
                                                          .price
                                                    )
                                                  }}% )</span>
                                                <small style="
                                                    color: grey;
                                                    font-weight: 100;
                                                    letter-spacing: 1px;
                                                  ">/piece</small></span>
                                            </span>
                                          </b>
                                          <p class="dsc">{{ productDetails.name }}</p>
                                          <p class="dsc">
                                            Made in
                                            {{
                                              productDetails.country_of_manufacture_id
                                                .name
                                            }}
                                          </p>
                                        </div>

                                        <div class="sales-btn-area">
                                          <div v-if="
                                            $store.getters.isExist(productDetails.id)
                                          " class="counter-btn">
                                            <button class="btn" @click="deductQty(productDetails)">
                                              -
                                            </button>

                                            <button class="value-btn" readonly>
                                              {{
                                                $store.getters.prodQty(productDetails.id)
                                              }}
                                            </button>

                                            <button class="btn" :disabled="productDetails.product_price[0]
                                              .quantity ===
                                              $store.getters.prodQty(productDetails.id)
                                              " @click="addQty(productDetails)">
                                              +
                                            </button>
                                          </div>
                                        </div>
                                        <div class="stock" v-if="
                                        productDetails.product_type == 1">
                                          <p class="stock-st">
                                            <b><span>Stock Status: </span>
                                              <span v-if="
                                                productDetails.product_price[0]
                                                  .quantity > 0
                                              ">{{
                                                productDetails.product_price[0].quantity
                                              }}</span>
                                              <span v-else>0</span> available only</b>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div v-if="
                                        !$store.getters.isExist(productDetails.id) &&
                                        productDetails.product_price[0].quantity > 0
                                      " class="cart-btn">
                                        <button @click="buyNowForSingleProduct(productDetails)"
                                          class="btn btn-primary buy-now">
                                          Buy Now
                                        </button>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div v-if="
                                        !$store.getters.isExist(productDetails.id) &&
                                        productDetails.product_price[0].quantity > 0
                                      " class="cart-btn">
                                        <button @click="
                                          addToBagForSingleProduct(productDetails)
                                          " class="btn btn-primary add-bag">
                                          Add to bag
                                        </button>
                                      </div>
                                    </div>

                                    <div v-if="productDetails.product_price[0].quantity < 1" class="col-md-12">
                                      <div class="form-group" style="margin-bottom: 1rem">
                                        <input type="text" class="form-control" v-model="name"
                                          placeholder="Your Name" />
                                      </div>
                                      <div class="form-group">
                                        <input type="text" class="form-control" v-model="mobileNo"
                                          placeholder="Your Mobile Number" />
                                      </div>
                                      <div class="form-group">
                                        <button type="button" class="btn btn-primary" @click="
                                          notifyMeForSubmit(
                                            name,
                                            mobileNo,
                                            productDetails
                                          )
                                          ">
                                          Notify Me
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="productDetails.product_type === 2">
                            <div v-if="!loading" class="row">
                              <div class="col-md-12">
                                <div class="col-md-6">
                                  <div class="thumbnail-img group-thumb-img col-md-12">
                                    <ul>
                                      <li class="thumb-img" v-for="image in singleProductImg === null
                                        ? productDetails.product_images.filter(
                                          (img) => img.web_id === 2
                                        )
                                        : singleProductImg">
                                        <img @click="asignImage(image.large_image)" :src="'https://eurasiasupplies.com/euadmin/' +
                                          image.thumb_image
                                          " alt="" />
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col-md-12 wrap-border">
                                    <div class="immage-zoomer">
                                      <inner-image-zoom class="large-img-sz" :src="'https://eurasiasupplies.com/euadmin/' +
                                        `${inImage == null
                                          ? productDetails.product_images.filter(
                                            (img) => img.web_id === 2
                                          )[0].large_image
                                          : inImage
                                        }`
                                        " :zoomScale="1" zoomType="hover" />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="product-details">
                                        <div class="product-desc">
                                          <h4>{{ productName }}</h4>
                                          <p>
                                            by
                                            <b class="brand-name">
                                              <router-link target="_blank" :to="{
                                                name: 'brand',
                                                params: {
                                                  slug: productDetails.brand.slug,
                                                },
                                              }">
                                                {{ productDetails.brand.name }}
                                              </router-link>
                                            </b>
                                          </p>
                                          <p v-if="origin">
                                            <b>Manufacturer Country: {{ origin }}</b>
                                          </p>
                                          <p v-if="sku" class="sku">
                                            <b>SKU : {{ sku }}</b>
                                          </p>
                                        </div>

                                        <div class="price">
                                          <b>
                                            <span class="price-tag">
                                              <h5 v-if="showPriceRange">
                                                <span>Sale :</span>
                                                {{ productDetails.min_price }}৳ -
                                                {{ productDetails.max_price }}৳
                                              </h5>
                                              <h5 v-if="!showPriceRange">
                                                <span v-if="
                                                  Object.keys(singleProct).length !== 0
                                                ">
                                                  <span v-if="
                                                    singleProct.product_price[0]
                                                      .offer_price > 0
                                                  ">
                                                    <span style="
                                                        font-size: 17px;
                                                        font-weight: 500;
                                                      ">Price :</span>
                                                    {{
                                                      singleProct.product_price[0]
                                                        .offer_price
                                                    }}
                                                  </span>
                                                  <span v-else><span style="
                                                        font-size: 17px;
                                                        font-weight: 500;
                                                      ">Price :</span>
                                                    {{
                                                      singleProct.product_price[0].price
                                                    }}</span>
                                                  TK
                                                </span>
                                              </h5>
                                            </span>
                                          </b>
                                          <p class="dsc">{{ productDetails.name }}</p>
                                        </div>
                                        <div class="product-color">
                                          <div class="color-name">
                                            <p><b>Color:</b></p>
                                          </div>
                                          <div class="color-shade clearfix">
                                            <ul>
                                              <li v-for="color in productDetails.groupProducts.filter(
                                                (x) =>
                                                  x.product.hasOwnProperty(
                                                    'product_color'
                                                  )
                                              )" :key="color.id">
                                                <img :class="selectedClrId === color.id
                                                  ? 'activeImg'
                                                  : ''
                                                  " v-if="
                                                    color.product.product_color !== null
                                                  " @click="productByColor(color, color.id)" style="
                                                    height: 25px;
                                                    width: 50px;
                                                    border-radius: 3px;
                                                  " :src="'https://eurasiasupplies.com/euadmin/' +
                                                    color.product.product_color
                                                      .color_image
                                                    " alt="" />
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div v-if="Object.keys(singleProct).length !== 0"
                                          class="col-md-12 sales-btn-area clearfix">
                                          <div v-if="$store.getters.isExist(singleProct.id)" class="counter-btn">
                                            <button class="btn" @click="deductQty(singleProct)">
                                              -
                                            </button>
                                            <button class="value-btn" readonly>
                                              {{ $store.getters.prodQty(singleProct.id) }}
                                            </button>
                                            <button class="btn" :disabled="singleProct.product_price[0].quantity ===
                                              $store.getters.prodQty(singleProct.id)
                                              " @click="addQty(singleProct)">
                                              +
                                            </button>
                                          </div>
                                        </div>
                                        <div v-if="Object.keys(singleProct).length !== 0" class="stock clearfix">
                                          <p v-if="
                                            singleProct.hasOwnProperty('product_price')
                                          " class="stock-st">
                                            <b><span>Stock Status:</span>
                                              {{ singleProct.product_price[0].quantity }}
                                              available only</b>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <!--                                    <div v-if="Object.keys(singleProct).length !== 0 " class="col-md-6">
                                      <div
                                          v-if="!$store.getters.isExist(singleProct.id) && singleProct.product_price[0].quantity>0"
                                          class="cart-btn">
                                        <button @click="buyNow(singleProct)" class="btn btn-primary buy-now">Buy Now
                                        </button>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div v-if="Object.keys(singleProct).length !== 0 " class="cart-btn">
                                        <button
                                            v-if="!$store.getters.isExist(singleProct.id) && singleProct.product_price[0].quantity>0"
                                            @click="addToBag(singleProct)" class="btn btn-primary add-bag">Add to bag
                                        </button>
                                      </div>
                                    </div>-->
                                    <div class="col-md-12">
                                      <div v-if="groupProductMsg" class="alert alert-danger" role="alert">
                                        {{ groupProductMsg }}
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="cart-btn">
                                        <button @click="buyNow()" class="btn btn-primary buy-now">
                                          Buy Now
                                        </button>
                                      </div>
                                    </div>
                                    <div v-if="!$store.getters.isExist(singleProct.id)" class="col-md-6">
                                      <div class="cart-btn">
                                        <button @click="addToBag()" class="btn btn-primary add-bag">
                                          Add to bag
                                        </button>
                                      </div>
                                    </div>
                                    <div v-if="Object.keys(singleProct).length !== 0">
                                      <div v-if="singleProct.product_price[0].quantity < 1" class="col-md-12">
                                        <div class="form-group" style="margin-bottom: 1rem">
                                          <input type="text" class="form-control" v-model="name"
                                            placeholder="Your Name" />
                                        </div>
                                        <div class="form-group">
                                          <input type="text" class="form-control" v-model="mobileNo"
                                            placeholder="Your Mobile Number" />
                                        </div>
                                        <div class="form-group">
                                          <button type="button" class="btn btn-primary" @click="
                                            notifyMeForSubmit(
                                              name,
                                              mobileNo,
                                              singleProct
                                            )
                                            ">
                                            Notify Me
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" @click="quickViewModal = false">
                            Close
                          </button>
                          <!--<button type="button" class="btn btn-primary"
                                  @click="notifyMeForSubmit(name, mobileNo, notifyMeData)">
                              Notify Me
                          </button>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="text-center">
            <img v-if="apiLoad" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height: 80px" alt="logo" />
            <span v-else>
              <a class="btn btn-primary" v-if="category.total_product === allProduct.length ? false : true"
                @click="loadMoreProduct">Load More</a>
            </span>
          </div>
          <br />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import "../../assets/product_common_global.css";
import axios from "../../axios";
!(function (f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
// window.fbq("init", "777792696884238"); old
window.fbq("init", "1996185650807019");
window.fbq("track", "PageView", { value: 0, currency: "BDT" });
export default {
  name: "CategorySection",
  props: [],
  data() {
    return {
      showModal: false,
      quickViewModal: false,
      notifyMeData: "",
      mobileNo: "",
      name: "",
      proPrice: 0,
      inImage: null,
      singleProductImg: null,
      showPriceRange: true,
      productName: "",
      brandName: "",
      origin: "",
      slug: "",
      description: "",
      price: "",
      sku: "",
      deliveryCharge: "",
      colors: [],
      sizes: [],
      singleProct: {},
      selectedClrId: 0,
      isSelected: false,
      img: "",
      totalProduct: [],
      allProduct: [],
      apiLoad: false,
      productLoading: false,
      groupProductMsg: "",
    };
  },
  metaInfo() {
    return {
      title: this.category.name,
      titleTemplate: "%s | Eurasia",
      meta: [
        /*{
            name: "description",
            content: this.productDetails.meta_description,
        },
        {
            name: "keyword",
            content: this.productDetails.meta_keyword,
        },*/
      ],
    };
  },
  components: {
    "inner-image-zoom": InnerImageZoom,
  },
  watch: {
    $route(newVlue, oldValue) {
      if (newVlue.params.slug !== oldValue.params.slug) {
        this.$store.dispatch("setPage", 1);
      }
      if (newVlue.query.page === undefined) {
        this.$store.dispatch("setPage", 1);
      }
      this.$store.dispatch("getCatagory", this.$route.fullPath);
      this.$store.dispatch("loadMorePage", 1);
      this.productLoading = true;
      axios
        .get(this.$route.fullPath + "?page=" + 1)
        .then(
          (response) => (
            (this.productLoading = false), (this.allProduct = response.data.data.products)
          )
        );
    },
    // $route() {
    //     this.$store.dispatch("getCatagory", this.$route.fullPath);
    //     // console.log("Category Page Cat :=", this.category)
    // }
  },
  computed: {
    ...mapGetters([
      "shoppingBag",
      "productDetails",
      "accessToken",
      "category",
      "getPage",
      "loading",
      "loadMorePage",
      "productColor",
      "productSize",
    ]),
  },
  mounted() {
    this.$store.dispatch("getCatagory", this.$route.fullPath);
    this.$store.dispatch("loadMorePage", 1);
    axios
      .get(this.$route.fullPath + "?page=" + 1)
      .then((response) => (this.allProduct = response.data.data.products));
  },

  methods: {
    addToBagForSingleProduct(product) {
      if (product.product_type === 1 || product.product_type === 3) {
        product.qty = 1;
        product.cartImage = product.product_images.filter(
          (img) => img.web_id === 2
        )[0].thumb_image;
        product.product_price[0].offer_price > 0
          ? (product.unitPrice = product.product_price[0].offer_price)
          : (product.unitPrice = product.product_price[0].price);
        this.$store.dispatch("addToBag", product);
      } else {
        product.qty = 1;
        product.cartImage = product.product_images.filter(
          (img) => img.web_id === 2
        )[0].thumb_image;
        product.min_offer_price > 0
          ? (product.unitPrice = product.min_offer_price)
          : (product.unitPrice = product.min_price);

        this.$store.dispatch("addToBag", product);
      }
      //this.$store.dispatch('sohwCart')
    },
    buyNowForSingleProduct(product) {
      // console.log("Product=", product);
      if (product.product_type === 1 || product.product_type === 3) {
        product.qty = 1;
        product.cartImage = product.product_images.filter(
          (img) => img.web_id === 2
        )[0].thumb_image;
        product.product_price[0].offer_price > 0
          ? (product.unitPrice = product.product_price[0].offer_price)
          : (product.unitPrice = product.product_price[0].price);
        this.$store.dispatch("addToBag", product);
      } else {
        product.qty = 1;
        product.cartImage = product.product_images.filter(
          (img) => img.web_id === 2
        )[0].thumb_image;
        product.min_offer_price > 0
          ? (product.unitPrice = product.min_offer_price)
          : (product.unitPrice = product.min_price);

        this.$store.dispatch("addToBag", product);
      }
      if (this.accessToken) {
        this.$router.push("/checkout").catch(() => { });
      } else {
        this.$router.push({ name: "login" }).catch(() => { });
      }
    },
    addToBag() {
      if (this.isSelected === false) {
        this.groupProductMsg = "Please select color or size.";
        //alert("Please select color/size");
      } else {
        if (this.singleProct.product_price[0].quantity > 0) {
          // console.log("single ===>", this.singleProct);
          this.singleProct.cartImage = this.singleProct.product_images.filter(
            (img) => img.web_id === 2
          )[0].thumb_image;
          this.singleProct.qty = 1;
          this.singleProct.product_price[0].offer_price > 0
            ? (this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price)
            : (this.singleProct.unitPrice = this.singleProct.product_price[0].price);

          this.$store.dispatch("addToBag", this.singleProct);
          // console.log("product from component", this.singleProct);
        } else {
          this.groupProductMsg = "Out of stock.";
        }
      }
    },
    buyNow() {
      if (this.isSelected === false) {
        this.groupProductMsg = "Please select color or size.";
        //alert("Please select color/size");
      } else {
        if (this.singleProct.product_price[0].quantity > 0) {
          if (
            this.singleProct.product_type === 1 ||
            this.singleProct.product_type === 3
          ) {
            this.singleProct.qty = 1;
            this.singleProct.cartImage = this.singleProct.product_images.filter(
              (img) => img.web_id === 2
            )[0].thumb_image;
            this.singleProct.product_price[0].offer_price > 0
              ? (this.singleProct.unitPrice = this.singleProct.product_price[0].offer_price)
              : (this.singleProct.unitPrice = this.singleProct.product_price[0].price);
            this.$store.dispatch("addToBag", this.singleProct);
          } else {
            this.singleProct.qty = 1;
            this.singleProct.cartImage = this.singleProct.product_images.filter(
              (img) => img.web_id === 2
            )[0].thumb_image;
            this.singleProct.min_offer_price > 0
              ? (this.singleProct.unitPrice = this.singleProct.min_offer_price)
              : (this.singleProct.unitPrice = this.singleProct.min_price);

            this.$store.dispatch("addToBag", this.singleProct);
          }
          if (this.accessToken) {
            this.$router.push("/checkout").catch(() => { });
          } else {
            this.$router.push({ name: "login" }).catch(() => { });
          }
        } else {
          this.groupProductMsg = "Out of stock.";
        }
      }
    },
    addQty(product) {
      // // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch("addQty", product);
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch("deductQty", product);
      }
      if (this.$store.getters.prodQty(product.id) == 0) {
        this.$store.dispatch("removeFromCart", product);
      }
      // if (product.qty <= product.product_price[0].quantity) {
      //   if (
      //       product.qty >= product.product_price[0].tier_quantity_three &&
      //       product.product_price[0].tier_quantity_three !== 0
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_three;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_two &&
      //       product.qty < product.product_price[0].tier_quantity_three
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_two;
      //   } else if (
      //       product.qty >= product.product_price[0].tier_quantity_one &&
      //       product.qty < product.product_price[0].tier_price_two
      //   ) {
      //     this.proPrice = product.product_price[0].tier_price_one;
      //   } else if (product.product_price[0].offer_price) {
      //     this.proPrice = product.product_price[0].offer_price;
      //   } else {
      //     this.proPrice = product.product_price[0].price;
      //   }
      // }
    },
    notifyMe(data) {
      this.notifyMeData = data;
      this.showModal = true;
    },
    notifyMeForSubmit(name, phone, product) {
      if (name !== "" && phone !== "" && product.id) {
        let notifyDataForPass = {
          name: name,
          phone: phone,
          product_id: product.id,
        };
        this.$store.dispatch("soldOutNotifyMe", notifyDataForPass).then((res) => {
          // console.log("Response Data = ", res);
          if (res === undefined) {
            window.alert("Successfully Submitted.");
          }
        });
        // console.log("Data for pass: ", notifyDataForPass);
      }
      this.showModal = false;
    },
    addWishList(product) {
      if (this.accessToken !== null) {
        let productId = {
          product_id: product.id,
        };
        this.$store.dispatch("addToWishlist", productId).then((res) => {
          if (res === undefined) {
            window.alert("Added to Wishlist.");
            product.is_wishlist = 1;
          }
        });
      } else {
        this.$router.push({ name: "login" }).catch(() => { });
      }
    },
    loadMoreProduct() {
      // console.log("Load more page ===>>>", this.loadMorePage);
      let page = parseInt(this.loadMorePage) + 1;
      this.$store.dispatch("loadMorePage", page);
      // console.log("Page = ", page);
      this.apiLoad = true;
      axios.get(this.$route.fullPath + "?page=" + page).then(
        (response) => (
          (this.apiLoad = false),
          response.data.data.products.forEach((item) => {
            this.allProduct.push(item);
          })
        )
      );
    },
    // loadMore() {
    //     this.page = this.getPage;
    //     this.page++;
    //     this.$store.dispatch("setPage", this.page);
    //     // console.log("Route =>", this.$route);
    //     // console.log("Slug =>", this.$route.params.slug);
    //     let path_name = "category";
    //     this.$router
    //         .push({
    //             name: path_name,
    //             query: { ...this.$route.query, page: this.page },
    //         })
    //         .catch(() => {});
    // },
    quickView(product) {
      // console.log(product);
      this.groupProductMsg = "";
      this.isSelected = false;
      this.$store.dispatch(
        "getProductDetails",
        product.slug + "/" + product.product_type
      );
      if (product.product_type === 1 || product.product_type === 3) {
        this.inImage = null;
        this.singleProductImg = null;
      }
      if (product.product_type === 2) {
        this.inImage = null;
        this.singleProductImg = null;
        this.singleProct = {};
        this.productName = this.productDetails.name;
        this.setProductInfo();
      }
      this.quickViewModal = true;
    },
    setProductInfo() {
      this.productName = this.productDetails.name;
      this.brandName = this.productDetails.brand.name;
      this.origin = this.productDetails.groupProducts[0].product.country_of_manufacture_id.name;

      this.slug = this.productDetails.brand.slug;

      this.description = this.productDetails.short_description;
      this.deliveryCharge = this.productDetails.flat_rate_value;
      if (this.colors) {
        this.colors = this.productColor;
      }
      if (this.productSize) {
        this.sizes = this.productSize;
      }
      this.sku = this.productDetails.sku;
      // console.log(this.productDetails);
    },
    productByColor(product, sId) {
      // console.log("===", product, sId);
      this.groupProductMsg = "";
      this.showPriceRange = false;
      this.productName = product.product.name;
      this.brandName = product.product.brand.name;
      this.origin = product.product.country_of_manufacture_id.name;
      this.slug = product.product.brand.slug;
      this.description = product.product.short_description;
      this.deliveryCharge = product.product.flat_rate_value;
      this.price = product.product.product_price[0].price;
      this.singleProct = product.product;
      this.sku = product.product.sku;
      this.isSelected = true;
      this.selectedClrId = sId;
      this.singleProductImg = product.product.product_images.filter(
        (img) => img.web_id === 2
      );
      this.inImage = product.product.product_images.filter(
        (img) => img.web_id === 2
      )[0].large_image;
      this.$store.dispatch("setZoomimage", product.product.product_images);
      // console.log(product.product);
    },
    asignImage(img) {
      this.inImage = img;
    },
    percentCalc(wasVal, newVal) {
      if (newVal && wasVal) {
        var per = (newVal * 100) / wasVal;
        return Math.round(100 - per);
      }
    },
  },
};
</script>

<!--<style scoped>
.thumbnail-img.group-thumb-img ul {
  text-align: left;
}
.thumbnail-img.group-thumb-img ul li {
  display: inline-block !important;
}
.color-shade ul li {
  margin: 5px 5px;
  float: left;
}
li.thumb-img {
  width: 100px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 3px;
}

li.thumb-img img {
  width: 100px;
  padding: 10px;
}

li.thumb-img:hover {
  cursor: pointer;
}
.immage-zoomer {
  background: #ddd9;
}
.product-add-deduct ul {
  padding: 0;
  margin: 0;
  text-align: center;
}

.product-add-deduct ul li {
  display: inline-block;
  margin: 0px 15px;
}
.product-add-deduct {
  margin: 5px 0px;
}

.product-add-deduct button {
  padding: 0;
  border: 1px solid #cc0011;
  color: red;
}
.product_area.product-area-wrap img {
  width: 100%;
  padding: 30px;
  max-width: 220px;
  max-height: 220px;
  background: #fff;
}

.product_title {
  padding: 0px 0px;
}

section.category_section {
  background: #e5f6f838;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.icon {
  cursor: pointer;
}

.load_more {
  cursor: pointer;
}

.product_title h4 {
  height: auto !important;
}

.product_title a h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 1px;
  color: #036
}

.product-area-wrap:hover .product_title a h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 30;
  -webkit-box-orient: vertical;
}

.product_action.action-details {
  text-align: center;
}

p.product-price {
  font-weight: bold;
  color: #f60;
  font-size: 15px;
}

.notify {
  width: 100%;
  padding: 10px 0px;
  font-size: 14px;
}

a.details {
  display: block;
  width: 100%;
  background: #434343;
  color: #ffffff;
  padding: 8px;
}

a.title-height {
  min-height: 60px;
  display: block;
}

/*Media Queries start here*/
/*Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
  .product_title h4 {
    font-size: 0.8rem;
    height: 35px;
  }
}

@media (min-width: 100px) and (max-width: 991.99px) {
  .mobile-hide {
    display: none;
  }
}

/*    New View*/
.row.product_row.custom-roduct-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: initial;
}

.custom-grid {
  flex-basis: calc(100% / 5);
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  height: 450px;
}

.bg-brand-product {
  background: #e5f6f838 !important;
}

.brand-p-area-section {
  width: 230px;
  padding: 15px 5px;
  margin: 0 auto;
}

.product_area.product-area-wrap.brand-p-area {
  width: 220px;
}

.brand-p-area-section:hover {
  background: #fff;
  box-shadow: 0px 0px 4px 1px #aaa;
}

.product_title a h4:hover {
  color: orange;
}

button.quick-view {
  width: 100%;
  font-size: 14px;
  padding: 10px 0px;
  margin-bottom: 15px;
}

button.quick-view:hover {
  background: red;
}

/*    New View*/
/*    Quick View*/
.product-details {
  text-align: left;
}

.product-desc h4 {
  margin-bottom: 5px;
}

b.brand-name {
  text-transform: uppercase;
  font-weight: 500;
}

.price {
  font-size: 1.3em;
}

span.price-tag {
  font-size: 1.25em;
  color: #990000;
}

.counter-btn button {
  border: 1px solid #ddd;
  font-weight: 900;
  font-size: 22px;
  padding: 0px 10px;
  text-align: center;
  color: #aaa;
}

button.value-btn {
  font-size: 15px;
  font-weight: 100;
  padding: 9px 9px;
  line-height: 1;
  color: #000;
}

.cart-btn {
  text-align: left;
  margin-top: 20px;
}

.cart-btn .btn {
  border-radius: 50px;
  width: 80%;
  font-size: 15px;
  padding: 7px 0px;
}

button.btn.btn-primary.buy-now {
  background: red;
  color: #fff;
  text-transform: capitalize;
}

button.btn.btn-primary.buy-now:hover {
  background: #000;
  border-color: #000;
}

button.btn.btn-primary.add-bag {
  background: #000;
  border-color: #000;
  color: #fff;
  text-transform: capitalize;
}

button.btn.btn-primary.add-bag:hover {
  background: red;
  border-color: red;
}

li.thumb-img {
  width: 100px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 3px;
}

li.thumb-img img {
  width: 100px;
  padding: 10px;
}

li.thumb-img:hover {
  cursor: pointer;
}

.counter-btn {
  margin-top: 30px;
}

b.brand-name a {
  color: #4F8ECF;
}

p.dsc {
  margin-top: 20px;
  font-size: 1.1em;
}

p.sku {
  margin: 20px 0px;
}

figure.iiz.large-img-sz {
  height: 328px;
}

.iiz__img {
  max-width: 100%;
  height: 328px !important;
  display: block;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
}

p.stock-st {
  margin: 20px 0px;
  color: red;
}

p.stock-st span {
  color: #35b035;
}

.col-md-4.wrap-border {
  border-right: 1px solid #ddd;
  margin-bottom: 20px;
}

.modal-ku {
  width: 750px;
  margin: auto;
}

.eu-modal-header {
  background: red;
  padding: 10px;
}

.eu-modal-title {
  display: inline;
}

/*    Quick View*/
nav.breadcrumb ul li {
  display: inline-block;
  padding: 0px 3px;
}

nav.breadcrumb ul li a {
  color: red
}

nav.breadcrumb {
  text-align: left;
}

button.plus.product-add-btn {
  padding: 10px 0px;
  font-size: 14px;
}

i.fa.fa-minus, i.fa.fa-plus {
  color: #010101;
  padding: 3px 7px;
  font-size: 11px;
}

@media (min-width: 300px) and (max-width: 767.98px) {
  .custom-grid {
    flex-basis: calc(100% / 2);
    height: 385px;
  }

  .brand-p-area-section {
    width: 165px;
  }

  .product_area.product-area-wrap.brand-p-area {
    width: 165px;
  }

  .product_area.product-area-wrap img {
    padding: 5px;
    max-width: 170px;
  }

  button.quick-view {
    font-size: 12px;
    padding: 6px 0px;
  }

  .notify {
    padding: 7px 0px;
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  button.quick-view {
    font-size: 12px;
    padding: 6px 0px;
  }

  .notify {
    padding: 7px 0px;
    font-size: 12px;
  }
}

@media (max-width: 991.98px) {
  .immage-zoomer {
    width: 100% !important;
  }

  span.iiz__btn.iiz__hint {
    display: none !important;
  }

  figure.iiz.large-img-sz {
    height: 250px;
  }

  li.thumb-img {
    width: 50px;
  }

  li.thumb-img img {
    width: 50px;
    padding: 0px;
  }

  .modal-ku {
    width: 100% !important;
  }

  .modal-content {
    height: 100vh;
    overflow: scroll;
  }

  .iiz__hint {
    display: none;
  }
}
</style>-->
